<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">积分充值</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
                    <el-form-item  style="display:flex;" label="状态：">
						<el-select style="margin-left:20px;" v-model="seachForm.status" clearable placeholder="请选择状态">
							<el-option v-for="item in enterOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(seachForm)">查询</el-button>
					</el-form-item>
                    
				</el-form>
			</div>
            <div>
                <el-button type="primary" @click="addshow = true">积分充值</el-button>
            </div>
		</div>
        <div class="jifen_top"><p>对公转账金额（元）：<span>{{transferAmountSum}}</span></p><p>到账积分：<span>{{accountIntegralSum}}</span></p></div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" ref="elTable" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="createTime" label="提交时间" ></el-table-column>
				<el-table-column prop="transferAmount" label="对公转账金额（元）" ></el-table-column>
				<el-table-column prop="corVoucher" label="对公凭证" >
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="showPreview(scope.$index, data)" type="text" size="small">
							预览
						</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="状态" >
                    <template slot-scope="scope">
                        <span >{{scope.row.status == 0 ? '审核中' : scope.row.status == 1 ? '审核通过' : '审核拒绝'}}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="checkTime" label="审核时间" >
                    <template slot-scope="scope">
                        <span >{{scope.row.status == 0 ? '-' : scope.row.checkTime}}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="checkRemark" label="审核说明" >
                    <template slot-scope="scope">
                        <span >{{scope.row.status == 0 ? '-' : scope.row.checkRemark}}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="accountIntegral" label="到账积分" >
					 <template slot-scope="scope">
                        <span >{{scope.row.status == 0 ? '-' : scope.row.status == 1 ? scope.row.accountIntegral : '0'}}</span>
                    </template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 对公凭证 -->
		<el-dialog :visible.sync="previewShow"  width="600px" title="对公凭证预览">
            <img :src="previewUrl"  class="voucher">
        </el-dialog>


        <!-- 积分充值弹出层 -->
		<el-dialog :visible.sync="addshow" width="600px" :before-close="handleClose" title="积分充值">
			<el-form class="form_box" label-position='left' ref="form" :hide-required-asterisk='true' size="small">
				<el-form-item :required='true' >
					<template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:120px;text-align:right;padding-right:10px;"><span style="color:red">*</span>对公转账金额：</p>
							<el-input :maxlength="20" min="0" type="number" v-model="transferAmount" clearable placeholder="请输入对公转账金额"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item label="">
					<template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:120px;text-align:right;padding-right:10px;"><span style="color:red">*</span>对公凭证：</p>
							<el-upload class="avatar-uploader" action="api/web/tool/file/upload" :headers="headers" accept=".jpg,.jpeg,.png" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
								<img v-if="corVoucher" :src="corVoucher" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon photo"></i>
							</el-upload>
							<p class="del" v-if="corVoucher.length > 0" @click="corVoucher = ''">删除</p>
						</div>
					</template>
				</el-form-item>
				<el-form-item size="large" style="text-align:center;margin-top:50px;">
					<el-button @click='handleClose'>取消</el-button>
					<el-button type="primary" @click="onSubmit">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		integralrechargeList,integralrechargeUpdate
	} from "@/api/platform/index";
	export default {
		name: "integralrecharge",
		data() {
			return {
                seachForm:{
					status:'',
				},
                headers: {
					Authorization: this.$store.state.user.Token
				},
                transferAmountSum:'0.00',
                accountIntegralSum:'0.00',
				enterOptions: [{label: '审核中',value: 0},{label: '审核通过',value: 1},{label: '审核拒绝',value: 2}],
				data: [],
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				previewUrl:'',
				previewShow:false,
                addshow: false,
                transferAmount:'',
                corVoucher:'',
			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				integralrechargeList({
					status:this.seachForm.status,
					page: this.page,
					size: this.pageSize,
				}).then(res => {
                    this.transferAmountSum = res.data.transferAmountSum != null ? res.data.transferAmountSum : '0.00';
                    this.accountIntegralSum = res.data.accountIntegralSum != null ? res.data.accountIntegralSum : '0.00';
					this.data = res.data.rechargeList.records;
					this.total = res.data.rechargeList.total;
					this.currentPage = res.data.rechargeList.current;
					this.pageCount = res.data.rechargeList.pages;
					if (res.data.rechargeList.current > res.data.rechargeList.pages && res.data.rechargeList.pages != 0) {
						this.page = res.data.rechargeList.pages;
						this.list();
					}
				})
			},
            
            //搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},
			
			//关闭提醒
			handleClose(done) {
				this.corVoucher = '';
				this.transferAmount = '';
				this.addshow = false;
			},

			// 凭证弹出层
			showPreview(i,tab){
				this.previewUrl = tab[i].corVoucher != null ? tab[i].corVoucher : '';
				this.previewShow = true;
			},

            //图片上传
			handleAvatarSuccess(res, file) {
				if(res.code == 200){
					this.corVoucher = res.data.path;
				}else{
					this.$message.error(res.msg);
				}
			},

			//图片验证
			beforeAvatarUpload(file) {
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
				// const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('Banner图片只能是 JPG 或 PNG 格式!');
				}
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				// return isJPG && isLt2M;
				return isJPG
			},


			// 积分充值
			onSubmit(){
				let that = this;
				if(that.transferAmount.toString() == '' ||  Number(that.transferAmount) < 0){
					that.$message.warning('对公转账金额不能为空且不小于0');
					return;
				}else if(that.corVoucher == ''){
					that.$message.warning('对公凭证不能为空');
					return;
				}else{
					integralrechargeUpdate({
						transferAmount:that.transferAmount,
						corVoucher: that.corVoucher,
					}).then(res => {
						if(res.code == 200){
							that.$message.success(res.msg);
							that.corVoucher = '';
							that.transferAmount = '';
							that.addshow = false;
							that.page = 1;
							that.list();
						}else{
							that.$message.error(res.msg);
						}
					})
				}

			},

		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:160px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
.jifen_top{
    font-size: 16px;
    color: #333;
    margin-top: 20px;
    display: flex;
    align-content: center;
    justify-items: flex-start;
}
.jifen_top span{
    margin-right: 20px;
    font-weight: bold;
}
.del{
    font-size: 14px;
    color: blue;
    cursor: pointer;
    margin-left: 10px;
}
.voucher{
	display: block;
	width: 50%;
	margin: 0 auto;
}
</style>
